.feature-board {
  .show-border {
    border-right: solid 2px #999;
    //box-shadow: 2px 0px 2px 1px #999;
    &>div {
      height: 90vh;
      overflow-y: auto;
      margin-top: 1rem;
    }
  }
}
.card.feature {
  margin-top: 1rem;
  height: 11rem;
  border: solid 1px #ccc;
  box-shadow: 2px 1px 4px 2px #ccc;
  &.selected {
    box-shadow: 2px 1px 6px 3px #999;
    border-color: #999;
  }
  .card-body {
    padding: 1rem;
    padding-left: 0;
    padding-right: 1.5rem;
  }
  .card-title {
    font-weight: bold;
    color: #000;
  }
  .card-text {
    font-size: 75%;
    p {
      margin-bottom: 0;
    }
  }
  .votes {
    border-right: solid 2px #999;
    text-align: center;
    height: 8rem;
    .count {
      height: 26px;
      font-size: 200%;
      font-weight: bold;
      color: #000;
      line-height: 1em;
    }
    .vote {
      height: 25px;
      font-size: 150%;
      line-height: 1em;
      z-index: 99;
      position: relative;
      &.up.active {
        color: #0c0;
      }
      &.down.active {
        color: #c00;
      }
    }
    .status {
      font-size: 75%;
      color: #fff;
      background-color: #000;
      border: solid 1px #999;
      border-radius: 4px;
      &.pending {
        background-color: #fc0;
      }
      &.planned {
        background-color: #009;
      }
      &.inProgress {
        background-color: #067;
      }
      &.done {
        background-color: #090;
      }
      &.notPlanned {
        background-color: #900;
      }
      &.deleted {
        background-color: #f00;
      }
    }
  }
  .actions {
    border-left: solid 2px #999;
    height: 8rem;
    .sdc-action-button {
      display: inline-block;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
    }
  }
  &.pending {
    background-color: #fec;
  }
  &.planned {
    background-color: #ddf;
  }
  &.inProgress {
    background-color: #cef;
  }
  &.done {
    background-color: #dfd;
    opacity: .8;
  }
  &.notPlanned {
    opacity: .8;
    background-color: #fdd;
  }
  &.deleted {
    opacity: .6;
    background-color: #f99;
  }
}

.feature-details .actions {
  font-size: 3rem;
  margin-top: 24px;
  .sdc-action-button {
    padding: 1px;
    margin: 0;
    &.active {
      background-color: #fff;
    }
  }
}
.actions .sdc-action-button {
  &.active {
    border-color: #000;
  }
  &.disabled:hover {
    opacity: .25;
    cursor: default;
  }
}
.sdc-action-button {
  &.status-pending , &.status-pending:hover {
    color: #c93;
  }
  &.status-suggested , &.status-suggested:hover {
    color: #aa3;
  }
  &.status-planned , &.status-planned:hover {
    color: #009;
  }
  &.status-inProgress , &.status-inProgress:hover {
    color: #067;
  }
  &.status-done , &.status-done:hover {
    color: #090;
  }
  &.status-notPlanned , &.status-notPlanned:hover {
    color: #900;
  }
  &.status-deleted , &.status-deleted:hover {
    color: #a33;
  }
}
