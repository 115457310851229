.board-details {
  .actions {
    text-align: right;
    float: left;
    .sdc-action-button {
      display: inline-block;
      height: 5rem;
      line-height: 4.75rem;
      font-size: 4rem;
    }
  }
}
