$body-bg: #eee;

$brand-color: #333;

$primary: #333;

$zettel-color-editable:  #333;
$zettel-color-focus:     #111;
$zettel-bg:  #ddd;
$zettel-bg-focus: #fff;
$zettel-bg1: #225;
$zettel-border1: solid 1px #555;
$zettel-border2: solid 2px #777;

$bg-light-50: #406abf;
$bg-light-80: #bbb;
$bg-light-80-light: #aaa;
$bg-light-90: #ddd;
$bg-light-95: #eee;

$btn-focus-width:  0;
$input-bg: #ccc;
$input-color: #333;

$border-radius:    0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$navbar-padding-y:                  0;
$navbar-padding-x:                  0;
$nav-link-padding-y:                .9rem;

$navbar-light-color:                rgba($brand-color, .8);
$navbar-light-hover-color:          rgba($brand-color, 1);
$navbar-light-active-color:         rgba($brand-color, 1);
$navbar-light-disabled-color:       rgba($brand-color, .4);

$form-group-margin-bottom:          0;

$font-size-sm:                .75rem;

$input-btn-padding-y-sm:      .05rem;
$input-btn-padding-x-sm:      .125rem;
