.gmcd-feature-gallery {
  text-align: center;
  img {
    margin: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  div.wrapper {
    min-height: 325px;
  }
  div.caption {
    margin-top: 12px;
  }
}
