@import "../design/variables.scss";

.navbar {
  background-color: #ccc;
  border-color: #999;
  border-bottom: solid 2px #999;
  box-shadow: 0 0 6px 3px #bbb;
  .navbar-brand {
    color: $primary;
  }
  .navbar-nav li a.active {
    color: $primary;
    background-color: #bbb;
  }
  .app-version {
    color: #666;
    &:hover {
      color: #ccc;
      font-size: 150%;
      z-index: 1000;
      background-color: #000;
      border: solid 1px #666;
    }
  }
  .kasten-menu {
    border-right: solid 1px #66c;
  }
  .sdc-action-button {
    font-size: 24px;
    height: 38px;
    line-height: 32px;
    border: solid 1px #999;
    &.nav-link {
      margin-top: 7px;
      padding: 2px;
    }
  }
  .filters , .settings {
    .sdc-action-button {
      text-align: center;
      margin: 2px 0;
      padding: 2px;
      .caption {
        display: inline-block;
        font-size: 16px;
        line-height: 1em;
        border-width: 1px;
        border-style: solid;
        border-radius: 12px;
        padding: 2px 4px 1px;
        margin-left: 4px;
        margin-top: -6px;
        margin-right: 4px;
        vertical-align: middle;
      }
      &:hover {
        background-color: #ddd;
      }
      &.active {
        background-color: #ddd;
        box-shadow: inset 1px 1px 3px #666;
        cursor: default;
      }
    }
  }
}

a.navbar-brand {
  padding: 8px 12px;
}

