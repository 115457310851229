@import "./design/variables.scss";
@import "node_modules/bootstrap/scss/bootstrap";

html, body, #root {
  height: 100%;
}
body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  color: #666;
}

.btn-outline-primary:hover {
  background-color: #e5eeff;
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
.btn-outline-secondary.disabled {
  background-color: #cdf;
}

h4 {
  color: $gray-600;
}
.form-group {
  margin-bottom: 8px;
}
.col-form-label {
  font-size: 85%;
  color: $gray-500;
  padding-top: 4px;
  padding-bottom: 0;
  .language {
    color: #999;
  }
}

.nav-tabs {
  border-bottom-color: $gray-500;
  .nav-link {
    padding: 4px 12px;
  }
}
.nav-tabs .nav-link {
  border-top-color: $gray-200;
  border-left-color: $gray-200;
  border-right-color: $gray-200;
  border-bottom-color: $gray-500;
  &:hover {
    border-top-color: $gray-400;
    border-left-color: $gray-400;
    border-right-color: $gray-400;
  }
  &.active {
    border-top-color: $gray-500;
    border-left-color: $gray-500;
    border-right-color: $gray-500;
  }
  margin-right: 8px;
}
.tab-pane {
  padding: 8px;
  border-left: solid 1px $gray-500;
  border-right: solid 1px $gray-500;
  border-bottom: solid 1px $gray-500;
  min-height: 40vh;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.gmcd-select.Select--multi .Select-value {
  background-color: $gray-200;
  color: $gray-900;
  border-color: $gray-400;
  .Select-value-icon {
    background-color: theme-color-level("danger", -10);
    border-color: $gray-400;
  }
}

$themes: (
  "darkGray": (
    bg0: #111,
    bg1: #222,
    bg1a:#222,
    bg2: #333,
    bg3: #666,
    bg4: #888,
    bg6: #ccc,
    color0: #fff,
    color1: #ccc,
    color2: #999,
    link1: #ccd,
    link2: #aab,
  ),
  "lightGray": (
    bg0: #fff,
    bg1: #eee,
    bg1a: #ddd,
    bg2: #bbb,
    bg3: #aaa,
    bg4: #999,
    bg6: #777,
    color0: #000,
    color1: #222,
    color2: #555,
    link1: #334,
    link2: #556,
  ),
);

@function darkTheme($name,$hue) {
  $theme: (
    bg0: hsl($hue, 80,  5),
    bg1: hsl($hue, 60,  8),
    bg1a:hsl($hue, 50, 13),
    bg2: hsl($hue, 43, 23),
    bg3: hsl($hue, 33, 30),
    bg4: hsl($hue, 27, 37),
    bg6: hsl($hue, 50, 60),
    color0: hsl($hue, 100, 95),
    color1: hsl($hue, 100, 90),
    color2: hsl($hue,  33, 70),
    link1:  hsl($hue,  80, 80),
    link2:  hsl($hue,  90, 70),
  );
  @return ($name: $theme);
};

@function lightTheme($name,$hue) {
  $theme: (
    bg0: hsl($hue,100, 99),
    bg1: hsl($hue,100, 97),
    bg1a:hsl($hue, 80, 93),
    bg2: hsl($hue, 60, 85),
    bg3: hsl($hue, 43, 77),
    bg4: hsl($hue, 27, 57),
    bg6: hsl($hue, 50, 60),
    color0: hsl($hue, 33, 10),
    color1: hsl($hue, 50, 13),
    color2: hsl($hue, 33, 30),
    link1:  hsl($hue, 80, 30),
    link2:  hsl($hue, 90, 40),
  );
  @return ($name: $theme);
};

$themes: map-merge($themes,darkTheme("darkBlue"  ,240));
$themes: map-merge($themes,darkTheme("darkPink"  ,300));
$themes: map-merge($themes,darkTheme("darkRed"   ,  0));
$themes: map-merge($themes,darkTheme("darkOrange", 60));
$themes: map-merge($themes,darkTheme("darkGreen", 120));
$themes: map-merge($themes,darkTheme("darkCyan" , 180));

$themes: map-merge($themes,lightTheme("lightBlue"  ,240));
$themes: map-merge($themes,lightTheme("lightPink"  ,300));
$themes: map-merge($themes,lightTheme("lightRed"   ,  0));
$themes: map-merge($themes,lightTheme("lightOrange", 60));
$themes: map-merge($themes,lightTheme("lightGreen", 120));
$themes: map-merge($themes,lightTheme("lightCyan" , 180));

@each $theme, $styles in $themes {

  $border1 : solid 1px map-get($styles,"bg4");
  $border2 : solid 2px map-get($styles,"bg6");

  $bg0 : map-get($styles,"bg0");
  $bg1 : map-get($styles,"bg1");
  $bg2 : map-get($styles,"bg2");
  $bg3 : map-get($styles,"bg3");
  $bg4 : map-get($styles,"bg4");
  $bg6 : map-get($styles,"bg6");

  $color0 : map-get($styles,"color0");
  $color1 : map-get($styles,"color1");
  $color2 : map-get($styles,"color2");

  .theme-selector.#{$theme} {
    font-size: 150%;
    color: $color1;
    background-color: $bg3;
    padding: .25rem .5rem;
    &.selected {
      margin: .125rem;
      border: solid .125rem #f77;
    }
    &.inactive {
      opacity: .7;
      margin: .25rem;
    }
  }
  .fb-main.#{$theme} {
    background-color: $bg0;
    a {
      color: map-get($styles,"link1");
    }
    .navbar {
      .navbar-brand {
        background-color: $bg0;
        padding: .5rem .75rem;
        .app-name {
          font-size: 1.4rem;
          padding-left: .5rem;
          font-weight: bold;
        }
      }
      background-color: $bg1;
      border-color: $bg6;
      border-bottom: $border2;
      .navbar-nav li a.active {
        color: $color1;
        background-color: $bg2;
      }
      .navbar-nav .nav-item .sdc-action-button.nav-link {
        color: map-get($styles,"link1");
        background-color: $bg2;
        padding: 15px .5rem;
      }
      .app-version {
        color: #666;
        &:hover {
          color: #ccc;
          font-size: 150%;
          z-index: 1000;
          background-color: #000;
          border: solid 1px #666;
        }
      }
      .login-button {
        border-color: $bg6;
      }
      .board-menu {
        border-right: solid 1px $bg6;
      }
      .feature-search {
        margin-left: 1rem;
        .form-control {
          background-color: $bg2;
          border: $border1;
        }
      }
    }
    .content-editable , textarea.form-control , input.form-control {
      color: $color1;
      background-color: $bg3;
      border: $border1;
      &:focus {
        color: $color0;
        background-color: $bg4;
        border-color: $bg6;
      }
    }
    h3.zettel , .zettel-links , .zettel-details {
      background-color: $bg2;
      border: $border1;
      .col-form-label {
        color: $color2;
      }
    }
    h3.zettel {
      color: $color1;
    }

    .zettel-details {
      .md-link {
        color: map-get($styles,"link1");
        &:hover {
          color: map-get($styles,"link2");
        }
      }
    }

    .zettel-parents > .row {
      & > .zettel-parent:not(:first-child) {
        border-left: $border2;
      }
      & > .zettel-parent {
        padding: 0;
      }
    }
    .zettel-links > .row {
      & > .col:not(:first-child) {
        border-left: $border2;
      }
      & > .col {
        padding: 0;
      }
    }

    .zettel-links .text-center.no-padding {
      &:not(:first-child) {
        border-left: $border1;
      }
      padding: 0;
      .zettel-primary {
        border-top: none;
      }
      :last-child {
        border-bottom: none;
      }
    }
    .zettel-links .row , .zettel-links {
      min-height: 2rem;
    }
      .col {
        border-color: $bg4;
      }
    .zettel-primary {
      color: $color1;
      border-top:    $border1;
      border-bottom: $border1;
    }
    .zettel-secondary {
      color: $color2;
      border-bottom: $border1;
      &.zettel-self {
        color: $color1;
        background-color: $bg4;
      }
    }
    .sec-content-panel-wrapper {
      margin-top: 1rem;
    }
    .sec-content-panel {
      margin: 0;
      padding: .75rem;
      background-color: map-get($styles,"bg1a");
      color: $color0;
      h4 {
        margin-top: 12px;
        border-bottom: $border1;
      }
    }
    .ReactTable.-highlight .rt-tbody .rt-tr {
      &:hover {
        background-color: $bg2;
      }
      &.selected {
        background-color: $bg3;
        &:hover {
          background-color: $bg4;
        }
      }
    }
  }
}

.entry-filter {
  padding: 4px;
  border: solid 1px $gray-400;
  margin-bottom: 2px;
  .remove-filter {
    color: theme-color-level("danger", -8);
    &:hover {
      color: theme-color-level("danger", -1);
    }
  }
  &.disabled {
    font-size: 85%;
    border-color: transparent;
  }
}
.md-self {
  color: #090;
  font-weight: bold;
}
.md-label {
  &:not(:first-child) {
    &::before {
      content: " • ";
    }
  }
  &.bordered {
    font-weight: bold;
    border: solid 1px #ddd;
    padding: 4px;
    margin-right: 8px;
  }
}
.md-link {
  font-weight: bold;
  cursor: pointer;
  white-space: pre;
  &:hover {
    text-decoration: underline;
  }
  &.bordered {
    border: solid 1px #ddd;
    padding: 4px;
    margin-right: 8px;
  }
  .fa {
    margin-right: 4px;
  }
}
h3.zettel {
  .toggle {
    color: $gray-600;
    margin-left: 1rem;
    cursor: pointer;
    &:hover {
      color: $gray-300;
    }
  }
}
.markdown-header {
  position: relative;
  .toggle {
    color: $gray-400;
    cursor: pointer;
    border: solid 1px $gray-200;
    &:hover {
      color: $gray-700;
      border: solid 1px $gray-400;
    }
    .fa {
      margin: 0 4px;
    }
  }
}

.type-panel {
  &.expandable {
    h4 , .h4 {
      cursor: pointer;
    }
  }
  h4 , .h4 {
    &.collapsed {
      margin-bottom: 4px;
      border-bottom: solid 1px $gray-400;
    }
    &.expanded {
      margin-bottom: 0;
    }
    .fa {
      margin-right: 8px;
    }
  }
}
textarea.form-control , input.form-control {
  padding: 0 4px;
  border: solid 1px $bg-light-80-light;
  border-radius: 0;
  background-color: $zettel-bg;
  color: $zettel-color-editable;
  border-color: #999;
  &:focus {
    color: $zettel-color-focus;
    background-color: $zettel-bg-focus;
    border-color: #666;
    box-shadow: none;
  }
}
.zettel-details , .kasten-details {
  label {
    text-align: right;
    color: #666;
  }
  margin-top: .5rem;
  .content-editable {
    border: solid 1px $bg-light-80-light;
    color: $zettel-color-editable;
    background-color: $zettel-bg;
    margin: auto;
    border-color: #999;
    &:focus {
      color: $zettel-color-focus;
      background-color: $zettel-bg-focus;
      border: solid 1px #666;
      box-shadow: none;
    }
  }
}
.content-editable.wb-editing {
  border-color: $bg-light-80-light;
  padding-top: 0;
  &:focus {
    color: #ccf;
    border-color: $bg-light-80-light;
    background-color: #999;
    box-shadow: 0 0 0 .2rem rgba(84, 176, 156, .25);
  }
}

.inline-help-btn {
  color: #9bf;
  cursor: help;
  &:hover {
    color: #05f;
  }
}
.panel-toolbar {
  margin-bottom: 8px;
  .btn-sm , .btn-group-sm > .btn {
    font-size: 0.75rem;
    padding: 0.1rem 0.25rem;
  }
}

.board-menu-entry {
  .board-name {
    margin-right: 8rem;
  }
  .details {
    opacity: .5;
    font-size: 85%;
    margin-top: 5px;
  }
  &.active {
    .details {
      opacity: 1;
    }
  }
}

.dropdown-item {
  &:hover {
    background-color: #eee;
  }
  &.active {
    color: #000;
    background-color: #ccc;
    &:hover {
      background-color: #bbb;
    }
  }
}

.zettel-parents {
  margin-bottom: .5rem;
}
.zettel-verweise {
  margin-top: .5rem;
}

.zettel-parents , .zettel-verweise , .zettel-links {
  .col:not(:first-child) {
    border-left: $zettel-border1;
  }
}

h3.zettel {
  padding: .25rem;
  text-align: center;
  .cycle-priority {
    cursor: pointer;
  }
}
h3.zettel , .zettel-links , .zettel-details {
  .zettel-primary , .zettel-secondary.col {
    padding: .25rem;
  }
  .markdown-panel {
    border: none;
  }
  .sdc-action-button.action-create {
    color: #272;
    &:hover {
      color: #6c6;
    }
  }
}
.zettel-details {
  font-size: 150%;
  padding: .375rem .75rem;
  .row {
    margin-bottom: .125rem;
  }
  .viewing {
    .field-title {
      color: #333;
      font-size: 125%;
      font-weight: bold;
      border-bottom: solid 1px #999;
      padding-bottom: 8px;
      margin-bottom: 12px;
    }
  }
  .broken-link {
    color: #c99;
  }
}
.feature-search {
  .search-result , .pinned-result {
    margin-top: .25rem;
    padding: .25rem .5rem .25rem .25rem;
  }
  .search-result {
    .fa-2x {
      font-size: 1.4rem;
    }
  }
  .pinned-result {
    cursor: pointer;
    .fa-2x {
      font-size: 1.5rem;
    }
  }
}
.zettel-link {
  cursor: pointer;
  &:hover {
    color: #99f;
  }
}
.delete-button {
  color: #933;
  &:hover {
    color: #f99;
  }
}

.details {
  font-size: 10px;
  line-height: 1em;
}
